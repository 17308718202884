import React from "react";
import Typography from "@mui/material/Typography";

function ResultsFooter() {
  return (
    <>
      <Typography paragraph={true}>We are pleased to provide the above list of suppliers for the service (Asbestos Survey) with regard to a property located in your postcode district.</Typography>
      <Typography paragraph={true}>An email with the above prices and contact details has been sent to you, so you can refer to it later at your convenience.</Typography>
      <Typography paragraph={true}>If you need more information or help in your house purchase, please call us on 0330 088 2050.</Typography>

    </>
  );
}

export default ResultsFooter;
